<template lang="pug">
  .sale-settings
    .common
      .ota-name(@click="isExpandedOtas = !isExpandedOtas")
        | {{ $t("inventory_management.inventory_long_period.setting_list.all_ota") }}
        AppChevronIcon.m-l-10(:up="isExpandedOtas")
      .sale-stop
        AppRadioButton(
          :value="isCommonAddSaleStop"
          :indeterminate="isCommonIndeterminatedAddSaleStop"
          @change="handleCommonAddSaleStop"
        )
        .tooltip-column
      .sale
        AppRadioButton(
          :value="isCommonDeleteSaleStop"
          :indeterminate="isCommonIndeterminatedDeleteSaleStop"
          @change="handleCommonDeleteSaleStop"
        )
    TransitionGroup(
        name="fade-zoom"
        appear
        tag="div"
    )
      .ota(
        v-if="isExpandedOtas"
        v-for="ota in otas"
        :key="ota.id"
      )
        .ota-name {{ ota.name }}
        .sale-stop
          AppRadioButton(
            :value="saleSettings[ota.id].add_sale_stop"
            @change="handleChangeAddSaleStop(ota, $event)"
          )
          .tooltip-column
        .sale
          AppRadioButton(
            :value="saleSettings[ota.id].delete_sale_stop"
            @change="handleChangeDeleteSaleStop(ota, $event)"
          )
</template>

<script>
  import { every, some, reduce } from "lodash-es"

  export default {
    components: {
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppChevronIcon: () => import("@/components/elements/AppChevronIcon")
    },

    props: {
      saleSettings: {
        type: Object,
        default: () => new Object()
      }
    },

    data() {
      return {
        isExpandedOtas: false
      }
    },

    computed: {
      otas() {
        return this.$store.getters.otasWithoutNicolas
      },

      isCommonAddSaleStop({ isSelectedEvery }) {
        return isSelectedEvery("add_sale_stop")
      },

      isCommonDeleteSaleStop({ isSelectedEvery }) {
        return isSelectedEvery("delete_sale_stop")
      },

      isCommonIndeterminatedAddSaleStop({ isSelectedSome, isCommonAddSaleStop }) {
        return isSelectedSome("add_sale_stop") && !isCommonAddSaleStop
      },

      isCommonIndeterminatedDeleteSaleStop({ isSelectedSome, isCommonDeleteSaleStop }) {
        return isSelectedSome("delete_sale_stop") && !isCommonDeleteSaleStop
      }
    },

    methods: {
      isSelectedEvery(field) {
        return every(this.saleSettings, item => item[field])
      },

      isSelectedSome(field) {
        return some(this.saleSettings, item => item[field])
      },

      handleCommonAddSaleStop(add_sale_stop) {
        this.sendChange(
          reduce(
            this.otas,
            (obj, { id }) => {
              obj[id] = { add_sale_stop, delete_sale_stop: false }

              return obj
            },
            {}
          )
        )
      },

      handleCommonDeleteSaleStop(delete_sale_stop) {
        this.sendChange(
          reduce(
            this.otas,
            (obj, { id }) => {
              obj[id] = { add_sale_stop: false, delete_sale_stop }

              return obj
            },
            {}
          )
        )
      },

      handleChangeAddSaleStop({ id }, add_sale_stop) {
        this.sendChange({ ...this.saleSettings, [id]: { add_sale_stop, delete_sale_stop: false } })
      },

      handleChangeDeleteSaleStop({ id }, delete_sale_stop) {
        this.sendChange({ ...this.saleSettings, [id]: { delete_sale_stop, add_sale_stop: false } })
      },

      sendChange(sale_settings) {
        this.$emit("change", { sale_settings })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/set-inventory-for-long-period.sass"

  .sale-settings
    padding-bottom: 10px
    padding-top: 10px
    font-size: 0.75em
    width: $sale-stop-column-width

    .common
      font-weight: 700

      .ota-name
        cursor: pointer
        text-transform: uppercase

    .common,
    .ota
      align-items: center
      display: flex
      height: 25px
      width: 100%
      justify-content: space-between

      .ota-name
        text-align: right
        width: $ota-name-width

      .tooltip-column
        width: 20px

      .sale-stop,
      .sale
        display: flex
        justify-content: flex-start
        width: $sale-stop-header-width

      .sale
        width: $delete-sale-stop-header-width
</style>
